<template>
  <div class="contents" v-if="hasCert == 2">
    <base-text-contents
        :infoTitle="textContents_attr.infoTitle"
        :text1="textContents_attr.text1"
        :text2="textContents_attr.text2">

      <div class="btnwrap" slot="slot_btns">
        <button type="button" @click="loadDoc('setup', null)" class="btn btn-block btn-primary btn-lg w200">신규 등록</button>
      </div>

    </base-text-contents>
  </div>

  <div class="contents"  v-else-if="hasCert == 1">
    <base-table-contents
        :caption="tableData_attr.caption"
        :heads="tableData_attr.heads"
        :items="tableData_attr.items"
        :status_fail_msg="tableData_attr.status_fail_msg"
        @toParent:visible1="val => visible1 = val"
        @toParent:deleteCurrRow="val => deleteRow(val)"
        @toParent:updateCert="loadDoc('setup', null)">

      <base-card-header slot="slot_card_header"
                        :headerTitle="headerTitle" />
    </base-table-contents>
  </div>

  <div class="contents" v-else>

  </div>

</template>
<script>
export default {
	name: 'pageAuthHometaxUser',
	data: () => ({
		// Variable
		hasCert: 0,

		// Components attrs
		headerTitle: '인증서 정보 관리',
		tableData_attr: {
			caption: '인증서 정보 관리표',
			heads: [
				{col: '인증서명', style: ''},
				{col: '유효기간', style: 'width:190px'},
				{col: '등록일자', style: 'width:110px'},
				{col: '상태', style: 'width:90px'},
				{col: '관리', style: 'width:220px'},
			],
			items: [],
			status_fail_msg: [],
		},
		textContents_attr: {
			infoTitle: '인증정보가 등록되지 않았습니다.', 
			text1: '신규등록 버튼을 클릭하여 인증정보를 등록 하세요.',
			text2: '(세금)계산서, 현금영수증 등을 수집하기 위한 인증정보를 등록합니다.',
		},
		prefix:"api/auth/hometax",

		deleteRowIdx: "",
	}),
	methods: {
		deleteRow(idx){
			let delYn = confirm("인증서 정보를 삭제하시겠습니까?");
			if(!delYn) return false;
			
			var that = this;
			this.$cAxios.post(this.prefix + "/user/cert/remove", idx)
			.then(()=>{
				alert("삭제되었습니다");
				that.hasCert = 2;
				
				let verifyResult = [];
				that.$set(that.tableData_attr,'status_fail_msg',verifyResult);

				let resData = [];

				that.$set(that.tableData_attr,'items', resData);
			})
			.catch((err)=>{
				if(err.response){
					alert(err.response.data.message);
				}
			});
		},
		initCertInquiry(){
			var that = this;
			this.$cAxios.post(this.prefix + "/user/cert/inquiry")
			.then((res)=>{
				if(res.data != null){
					that.hasCert = 1;
					
					var status = "";
					let verifyResult = [{}];
					if(res.data.status == "VALID"){
						//정상 인증서
						status = "success";
						verifyResult = [];
						that.$set(that.tableData_attr,'status_fail_msg',verifyResult);
					}else{
						//인증서 오류(등록오류, 검증오류, 미검증)
						status= "failed";
						verifyResult = [{idx: '1', target: res.data.certNm, status_desc: res.data.statusDesc}];
						that.$set(that.tableData_attr,'status_fail_msg',verifyResult);
					}

					let resData = [{
						idx: '1', 
						tds: [
							{text: res.data.certNm},
							{text: res.data.certPubDate + " ~ " + res.data.certEndDate},
							{text: res.data.regDt}
						],
						td_status: status
					}];

					that.$set(that.tableData_attr,'items', resData);

				}
			})
			.catch(()=>{
				that.hasCert = 2;
			});
		
		},
		loadDoc (s_op, s_inJson) {
			var that = this;
				
			let loadNxClient = (v_s_op, v_s_inJson) => {
				
				let sUrl = "https://127.0.0.1:16565/?op=";
				if(v_s_op == 'certSelect'){
					sUrl = "https://127.0.0.1:16566/?op=";
				}

				this.$cAxios.post(sUrl+v_s_op, v_s_inJson ,{
					crossDomain: true,
					headers:{
						'Content-type':'application/json; charset=UTF-8',
					},
				}).then((res) =>{
					result(v_s_op, res.data);
				}).catch((err) => {
					if(err.request){ 
						if(err.config.url.indexOf('?op=setup') != -1){
							alert("공인인증서 등록 프로그램 설치가 필요합니다.");
							fnNxDownload();
						}
					}

				}).finally(() => {
				});

			}

			//첫 로드
			loadNxClient(s_op, s_inJson);
			var sendData = new Object();
			let result = (s_op, data) => {
				var errYn = data.errYn;
				
				if('setup' == s_op){
					if (errYn == 'N') {
						//certImageUrl : 배너이미지
						loadNxClient('certSelect', '{"certImageUrl": "", "nxKeypad": ""}');
					}
				} else if ('certSelect' == s_op) {
					//인증서 선택
					sendData.certNm = data.cert_nm; //인증서명
					sendData.certPubDate = data.pub_dt;
					sendData.certEndDate = data.end_dt;
					sendData.certOrgNm = data.org_nm;//인증기관명 (yessign 등...)
					sendData.certPolicyId = data.oid;
					sendData.certSerialNo = data.sn;
					sendData.password = data.cert_pw;

					callExecute(data);
				} else if ('execute' == s_op) {
					if (errYn == 'N') {
						sendData.signCert = data.DER2PEM;
						sendData.signPri = data.KEY2PEM;

						sendCertData(JSON.stringify(sendData));
					}
				}
				

			}


			let callExecute = (data) => {
				var inJson = new Object();
				inJson.orgCd = 'common';
				inJson.svcCd = 'getCertInfo';
				inJson.appCd = 'ant';
				inJson.signCert = data.file1; 
				inJson.signPri = data.file2;
				inJson.signPw = data.cert_pw;

				loadNxClient('execute', JSON.stringify(inJson));
			}

			let sendCertData = (sendData) => {  
				this.$cAxios.post(this.prefix + "/user/cert/regist", sendData,{
					headers : {
						'Content-Type':'application/json'
					}
				}).then(()=>{
					alert("인증서가 등록되었습니다.");
				}).catch((err)=>{
					if(err.response){
						alert(err.response.data.message);
					}
				}).finally(()=>{
					that.initCertInquiry();
				});

			}

			let fnNxDownload = () => {
				location.href = "/files/ExAdapter_Web_Setup_20200611.exe";
			}

		}
	},
	created() {
		this.initCertInquiry();
	}
}
</script>